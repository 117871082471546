<template>
  <v-row justify="center">
    <v-dialog :value="true" max-width="600" @input="$emit('close-dialog')">
      <v-card class="pa-8">
        <!-- init step -->
        <v-row class="justify-end">
          <v-btn icon color="primary" @click="$emit('close-dialog')">
            <v-icon v-text="'close'" />
          </v-btn>
        </v-row>
        <v-row>
          <v-col cols="12" class="py-1 px-3">
            <h2 class="d-block mb-4" v-text="title" />
          </v-col>
          <v-col cols="12" class="py-1 px-3">
            <v-text-field
              v-model="form.name" outlined dense :label="$t('project.competition.matchLocations.editMatchLocation.name')"
              :error-messages="getFieldErrors('name')" @blur="$v.form.name.$touch()"
            />
          </v-col>
          <v-col cols="12" class="pt-0 px-3">
            <geolocation
              :location="updatedLocation" :is-dirty="$v.form.address.$dirty"
              :custom-label="$t('project.competition.matchLocations.editMatchLocation.address')"
              @update="data => { form.address = data.name; form.latitude = data.latitude; form.longitude = data.longitude }" @blur="$v.form.name.$touch()"
            />
          </v-col>
        </v-row>
        <v-row v-for="(field, index) in form.fields" :key="field.id">
          <v-col cols="12" class="d-flex align-center justify-space-between pt-0">
            <h2 v-text="`${$t('project.competition.matchLocations.editMatchLocation.fields.title')} ${index + 1}`" />
            <v-btn v-if="form.fields.length > 1" class="w-auto" icon small color="primary" @click="deleteField(field.id)">
              <span v-text="$t('common.delete')" />
              <v-icon dark class="mx-1" v-text="'delete_outline'" />
            </v-btn>
          </v-col>
          <v-col cols="12" sm="6" class="py-1 px-3">
            <v-text-field
              v-model="field.name" outlined dense :label="$t('project.competition.matchLocations.editMatchLocation.fields.name')"
              :error-messages="getFieldErrors(`fields.$each.$iter.${index}.name`)" @blur="$v.form.fields.$each[index].name.$touch()"
            />
          </v-col>
          <v-col cols="12" sm="6" class="py-1 px-3">
            <v-text-field
              v-model.number="field.matchDurationInMinutes" :label="$t('project.competition.matchLocations.editMatchLocation.fields.matchDurationInMinutes')"
              :error-messages="getFieldErrors(`fields.$each.$iter.${index}.matchDurationInMinutes`)"
              type="number" min="1" outlined dense @keypress="validateKeyNumeric($event)"
              @blur="$v.form.fields.$each[index].matchDurationInMinutes.$touch()"
            />
          </v-col>
        </v-row>
        <v-row class="mb-9">
          <v-col cols="12" class="py-1 px-3">
            <v-btn class="w-auto" icon small color="primary" @click="addNewField()">
              <v-icon dark class="mx-1" v-text="'add_box'" />
              <span v-text="$t('project.competition.matchLocations.editMatchLocation.fields.addField')" />
            </v-btn>
          </v-col>
        </v-row>
        <v-card-actions class="pa-0">
          <v-row>
            <v-col cols="12" class="py-1 px-3 d-sm-flex justify-sm-center">
              <v-btn color="primary" class="w-100 w-sm-auto" @click="save" v-text="$t('common.save')" />
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import formUtils from '@/utils/mixins/formUtils'
import { getUniqueId } from '@/utils/formatters'

export default {
  name: 'EditMatchLocationDialog',
  components: {
    Geolocation: () => import('@/components/formFields/Geolocation'),
  },
  mixins: [formUtils],
  props: {
    organizationId: { type: String, default: null },
    projectId: { type: String, default: null },
    matchLocationId: { type: String, default: null },
  },
  data: () => ({
    form: { name: null, address: null, latitude: null, longitude: null, fields: [], getUniqueId },
  }),
  validations: {
    form: {
      name: { required },
      address: { required },
      latitude: { required },
      longitude: { required },
      fields: {
        required,
        $each: { id: { required }, name: { required }, matchDurationInMinutes: { required } },
      },
    },
  },
  computed: {
    ...mapGetters('competition', ['matchLocations']),
    updatedLocation: ({ form }) => form?.address,
    isNewMatchLocation: ({ matchLocationId }) => !matchLocationId,
    title() {
      return this.isNewMatchLocation ? this.$t('project.competition.matchLocations.editMatchLocation.titleNew')
        : this.$t('project.competition.matchLocations.editMatchLocation.titleEdit')
    },
    matchLocation: ({ matchLocationId, matchLocations }) => matchLocationId ? matchLocations.find(mL => mL.id === matchLocationId) : null,
  },
  async created() {
    this.form = {
      name: this.matchLocation?.name ?? null,
      address: this.matchLocation?.address ?? null,
      latitude: this.matchLocation?.latitude ?? null,
      longitude: this.matchLocation?.longitude ?? null,
      fields: this.matchLocation?.fields?.length
        ? [...this.matchLocation.fields.map(f => ({ ...f }))] : [{ id: getUniqueId(), name: null, matchDurationInMinutes: null }],
    }
  },
  methods: {
    async save() {
      const { organizationId, projectId, matchLocationId } = this
      if (!this.isFormValid()) {
        this.scrollToFirstError()
        return
      }
      if (this.isNewMatchLocation) {
        await this.runAsync(() => this.$store.dispatch('competition/createMatchLocation', { organizationId, projectId, data: this.form }))
      } else {
        await this.runAsync(() => this.$store.dispatch('competition/updateMatchLocation', { organizationId, projectId, matchLocationId, data: this.form }))
      }
      this.$emit('close-dialog')
    },
    addNewField() {
      this.form.fields.push({ id: getUniqueId(), name: null, matchDurationInMinutes: null })
    },
    async deleteField(fieldId) {
      const { projectId, matchLocationId } = this
      if (!this.isNewMatchLocation) {
        await this.$store.dispatch('competition/deleteReferenceFieldMatchLocation', { projectId, matchLocationId, fieldId })
      }
      this.form.fields = this.form.fields.filter(f => f.id !== fieldId)
    },
  },
}
</script>
